<template>
  <h1 class="title is-3">Impressum</h1>
  <p></p>

  <h2 class="subtitle is-5">Herausgeberin</h2>
  <div class="content">
    <p>
      Berlin-Brandenburgische Akademie der Wissenschaften <br>
      Jägerstraße 22/23 <br>
      10117 Berlin
      <br>
    </p>
    <p>
      Projektleiter am Standort Berlin: Dr. Alexander Geyken 
    </p>
  </div>

  <h2 class="subtitle is-5">Vertreter</h2>
  <div class="content">
    <p>
    Prof. Dr. Dr. h. c. mult. Christoph Markschies
    Tel.: +49 (0)30 20370-0
    </p>
  </div>

  <h2 class="subtitle is-5">Rechtsform</h2>
  <div class="content">
    <p>
    rechtsfähige Körperschaft des öffentlichen Rechts
    </p>
  </div>

  <h2 class="subtitle is-5">Umsatzsteuer-Identifikationsnummer</h2>
  <div class="content">
    <p>
    DE 167 449 058 (gemäß §27 a Umsatzsteuergesetz)
    </p>
  </div>

  <h2 class="subtitle is-5">Haftungsausschluss</h2>
  <div class="content">
    <p>
    Bei der Verlinkung bzw. Übernahme von Informationsangeboten Dritter hat das EVIDENCE Projekt alle vertretbaren Maßnahmen unternommen, die fremden Inhalte daraufhin zu überprüfen, ob durch sie durch eine zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Eine permanente inhaltliche Kontrolle der verknüpften externen Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Sollte das EVIDENCE Projekt feststellen oder von anderen darauf hingewiesen werden, dass ein Angebot Dritter, welches per Link verknüpft wurde, eine zivil- oder strafrechtliche Verantwortlichkeit auslöst, wird es den Link auf dieses Angebot unverzüglich aufheben. Das DWDS distanziert sich ausdrücklich von derartigen Inhalten.
    <br>
    Die Verwendung sämtlicher Daten des EVIDENCE Projekts erfolgt auf eigenes Risiko. Für Schäden, die sich aus der Verwendung abgerufener Informationen ergeben, wird keine Haftung übernommen.
    <br>
    Diese Hinweise gelten in gleicher Weise für Subdomains [subdomain].evidence.bbaw.de, deren inhaltliche Verantwortlichkeit bei den jeweiligen Autor*innen liegt.
    </p>
  </div>

</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImprintDE",
  components: {},
  setup(){}
});
</script>